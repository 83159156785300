import React, { Component } from "react";
import { observer } from "mobx-react";

@observer
class ViewMode extends Component {
  render() {
    const { recommendation, deficiencies } = this.props;

    return Object.keys(deficiencies).map((element) =>
      recommendation.applicationsForElement(element).map(
        (app) =>
          app.productName && (
            <p key={app.id}>
              Apply a total of{" "}
              <strong>{parseFloat(app.totalKgProduct).toFixed(2)}</strong>{" "}
              {app.units} of {app.productName} over an appropriate number of
              applications
            </p>
          )
      )
    );
  }
}

export default ViewMode;
