import React, { Component } from "react";

class Input extends Component {
  render() {
    const {
      fieldName,
      inputType,
      onChangeMethod,
      value,
      disabled = false,
      prepend = "d-sm-none d-xl-block",
      alignRight = false,
      rounded = "",
    } = this.props;

    return (
      <div className="input-group mb-2">
        <div className={"input-group-prepend " + prepend}>
          <div className={"input-group-text " + prepend}>{fieldName}</div>
        </div>
        <input
          type={inputType}
          className={
            `form-control ${rounded} ` + (alignRight ? "text-right " : "")
          }
          onChange={onChangeMethod}
          value={value}
          placeholder={fieldName}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default Input;
