import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import RecommendRow from "./RecommendRow";
import Input from "./Input";
import ProductApplication from "../../../models/Recommendation/ProductApplication";

@observer
class ProductSection extends Component {
  render() {
    const {
      elementName,
      element,
      applications,
      macro,
      recommendation,
    } = this.props;

    return (
      <div className="recommendation-form">
        <div className={"text-white px-3 py-2 mb-2 row deficient-" + element}>
          <div className="col-6">
            <h4>{elementName}</h4>
          </div>
          <div className="col-6 text-right">
            <h4>Deficient: {macro}</h4>
          </div>
        </div>
        {applications.map((application) => {
          return (
            <RecommendRow
              key={application.id}
              application={application}
              element={element}
              elementProducts={recommendation.productCatalog[element]}
              removeRow={recommendation.removeProductApplication}
            />
          );
        })}

        <div className="row">
          <div className="col-11 text-right">
            <Input
              fieldName="Total Kg Nutrient Applied"
              inputType="number"
              onChangeMethod={null}
              value={recommendation.totalNutrientApplied(element)}
              disabled={true}
              prepend={null}
              alignRight={true}
            />
          </div>
          <div className="col-1 add-row">
            <i
              className="fa fa-plus-circle fa-2x"
              onClick={() => {
                recommendation.addProductApplication(
                  new ProductApplication(element)
                );
              }}
            ></i>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default ProductSection;
