import { observable, action, computed } from "mobx";
import { v4 as uuid } from "uuid";

class ProductApplication {
  static fromJS(js) {
    const app = new ProductApplication();
    app.updateFromJS(js);
    return app;
  }
  @observable id = uuid();
  @observable element = "";
  @observable productName = "";
  @observable percentNutrient = "0";
  @observable totalKgProduct = "0";
  units = "Kg per 100m²";
  constructor(
    element,
    productName = "",
    percentNutrient = "",
    totalKgProduct = "",
    units = "Kg per 100m²"
  ) {
    this.element = element;
    this.productName = productName;
    this.percentNutrient =
      parseFloat(percentNutrient) > 0
        ? parseFloat(percentNutrient).toFixed(2)
        : "";
    this.totalKgProduct =
      parseFloat(totalKgProduct) > 0
        ? parseFloat(totalKgProduct).toFixed(2)
        : "";
    this.units = units;
  }

  @computed
  get nutrientApplied() {
    const totalNutrientApplied =
      (this.percentNutrient * this.totalKgProduct) / 100;
    return totalNutrientApplied == 0 ? "" : totalNutrientApplied.toFixed(2);
  }

  toJS = () => {
    return {
      element: this.element,
      product_name: this.productName,
      product_percent_nutrient: this.percentNutrient,
      rate: this.totalKgProduct,
      rate_units: this.units,
    };
  };

  updateFromJS = (js) => {
    this.element = js.element;
    this.productName = js.product_name;
    this.percentNutrient = js.product_percent_nutrient;
    this.totalKgProduct = js.rate;
    this.units = js.rate_units;
  };
}

export default ProductApplication;
