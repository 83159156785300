import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import ProductSection from "./ProductSection";

@observer
class EditMode extends Component {
  @action
  changeComments = (e) => {
    const { recommendation } = this.props;
    recommendation.comments = e.target.value;
  };

  render() {
    const {
      deficiencies,
      recommendation,
      defaultRecommendation,
      cancelEditMode,
      displayCog,
      resultId,
    } = this.props;

    return (
      <div className="application">
        {Object.keys(deficiencies).map((element) => (
          <ProductSection
            key={deficiencies[element].name}
            elementName={deficiencies[element].name}
            element={element}
            macro={deficiencies[element].macro}
            applications={recommendation.applicationsForElement(element)}
            recommendation={recommendation}
          />
        ))}

        <div className="container recommendation-comment">
          <textarea
            className="form-control comment"
            onChange={this.changeComments}
            value={recommendation.comments}
            placeholder="Comments... "
          />
        </div>

        <div className="row save-cancel">
          <div className="cancel col-sm-3 text-center">
            <a
              onClick={() => {
                recommendation.restoreMemento();
                cancelEditMode();
                displayCog("d-block");
              }}
              className="btn btn-danger"
            >
              CANCEL
            </a>
          </div>

          <div className="revert-to-default col-sm-5 col-lg-3 text-center text-wrap">
            <a
              onClick={() => {
                recommendation.updateFromJS(defaultRecommendation.toJS());
              }}
              className="btn btn-success"
            >
              RESTORE DEFAULTS
            </a>
          </div>

          <div className="offset-sm-1 offset-lg-3 col-sm-3 text-center">
            <a
              onClick={() => {
                recommendation.removeEmpty();
                recommendation.save(resultId);
                cancelEditMode();
                displayCog("d-block");
              }}
              className="btn btn-primary"
            >
              SAVE
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default EditMode;
