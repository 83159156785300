import React, { Component } from "react";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import Input from "./Input";
import Dropdown from "./Dropdown";

@observer
class RecommendRow extends Component {
  @action
  changeProductName = (e) => {
    const { application } = this.props;
    application.productName = e.target.value;
  };

  @action
  changePercentNutrient = (e) => {
    const { application } = this.props;
    application.percentNutrient = e.target.value;
  };

  @action
  changeTotalKgProduct = (e) => {
    const { application } = this.props;
    application.totalKgProduct = e.target.value;
  };

  render() {
    const { element, elementProducts, application, removeRow } = this.props;

    return (
      <div className="row recommendation">
        <div className="col-11">
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <div className="row">
                <div className="col-11 p-0 pl-3">
                  <Input
                    fieldName="Product"
                    inputType="text"
                    onChangeMethod={this.changeProductName}
                    value={application.productName}
                    rounded="rounded-0"
                  />
                </div>
                <div className="col-1 p-0 text-left">
                  <Dropdown
                    application={application}
                    elementProducts={elementProducts}
                    element={element}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <Input
                fieldName="% Nutrient"
                inputType="number"
                onChangeMethod={this.changePercentNutrient}
                value={application.percentNutrient}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Input
                fieldName="Total Kg Product"
                inputType="number"
                onChangeMethod={this.changeTotalKgProduct}
                value={application.totalKgProduct}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Input
                fieldName="Kg Nutrient Applied"
                inputType="text"
                onChangeMethod={null}
                value={application.nutrientApplied}
                disabled={true}
                alignRight={true}
              />
            </div>
          </div>
        </div>
        <div className="col-1 delete-row">
          <i
            className="fa fa-times fa-2x"
            onClick={() => removeRow(application.id)}
          ></i>
        </div>
      </div>
    );
  }
}

export default RecommendRow;
