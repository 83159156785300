import React, { Component } from "react";

class Dropdown extends Component {
  render() {
    const { element, elementProducts, application } = this.props;

    return (
      <div className="dropdown form-control p-0">
        <button
          className="btn btn-secondary dropdown-toggle p-0 w-100 h-100"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        />
        <table
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <thead />
          <tbody>
            {elementProducts.map((item, i) => {
              const e = element.toLowerCase() + "_pct";
              const percentNutrient = parseFloat(item[e] * 100).toFixed(2);
              return (
                <tr
                  key={item.id}
                  onClick={() => {
                    application.productName = item.name;
                    application.percentNutrient = percentNutrient;
                  }}
                  className={"dropdown-item"}
                >
                  <td className="pr-3">{item.name}</td>
                  <td className="w-100 text-right">{item.analysis}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Dropdown;
