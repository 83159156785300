import React, { Component } from "react";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";

@observer
class Deficiencies extends Component {
  render() {
    const { deficiencies } = this.props;
    return (
      <table className="table">
        <tbody>
          {Object.keys(deficiencies).map((d, i) => (
            <tr
              key={deficiencies[d].element}
              className={deficiencies[d].macro > 0 ? "deficient" : ""}
            >
              <td
                className={
                  deficiencies[d].macro > 0
                    ? "deficient-" + deficiencies[d].element
                    : ""
                }
              >
                {deficiencies[d].units} of {deficiencies[d].name} deficient
              </td>
              <td
                className={["text-right"]
                  .concat(
                    deficiencies[d].macro > 0
                      ? "deficient-" + deficiencies[d].element
                      : []
                  )
                  .join(" ")}
              >
                {deficiencies[d].macro}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default Deficiencies;
