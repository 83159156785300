import { observable, action } from "mobx";

class ProductCatalog {
  init() {
    this.getProducts().then(this.fillProductsList);
  }

  @observable Ca = [];
  @observable Mg = [];
  @observable K = [];
  @observable P = [];

  @action
  fillProductsList = (products) => {
    products.sort(this.naturalSort);
    this.Ca = products.filter((each) => {
      return each.ca_pct > 0;
    });
    this.Mg = products.filter((each) => {
      return each.mg_pct > 0;
    });
    this.K = products.filter((each) => {
      return each.k_pct > 0;
    });
    this.P = products.filter((each) => {
      return each.p_pct > 0;
    });
  };

  naturalSort = (a, b) =>
    String(a.name).localeCompare(
      String(b.name),
      navigator.languages[0] || navigator.language,
      {
        numeric: true,
        ignorePunctuation: true,
      }
    );

  getProducts = () => {
    return fetch("/api/fertilizers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  };
}

export default ProductCatalog;
