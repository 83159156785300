import { observable, action, computed } from "mobx";
import ProductApplication from "./ProductApplication";
import ProductCatalog from "./ProductCatalog";
import { v4 as uuid } from "uuid";

class Recommendation {
  static fromJS(js) {
    const rec = new Recommendation();
    rec.updateFromJS(js);
    rec.saveMemento();
    return rec;
  }

  productCatalog = new ProductCatalog();
  @observable applications = [];
  @observable comments = "";
  memento = {
    applications: [],
    comments: "",
  };

  totalNutrientApplied(element) {
    return this.applicationsForElement(element)
      .reduce((acc, cur) => {
        const applied = cur.nutrientApplied;
        return applied === "" ? acc : acc + parseFloat(applied);
      }, 0)
      .toFixed(2);
  }

  @action
  restoreMemento = () => {
    this.applications = Array.from(this.memento.applications);
    this.comments = this.memento.comments;
  };

  saveMemento = () => {
    this.memento.applications = Array.from(this.applications);
    this.memento.comments = this.comments;
  };

  @action
  addProductApplication = (productApplication) => {
    this.applications.push(productApplication);
  };

  @action
  removeProductApplication = (id) => {
    this.applications = this.applications.filter((row) => {
      return row.id != id;
    });
  };

  applicationsForElement = (element) => {
    return this.applications.filter((each) => each.element === element);
  };

  toJS = () => {
    const js = {
      products: this.applications.map((each) => each.toJS()),
      comments: this.comments,
    };
    return js;
  };

  updateFromJS = (js) => {
    this.applications.length = 0;
    js.comments === undefined
      ? (this.comments = "")
      : (this.comments = js.comments);

    js.products &&
      js.products.forEach((app) => {
        const newApp = ProductApplication.fromJS(app);
        this.applications.push(newApp);
      });
  };

  @action
  removeEmpty = () => {
    this.applications = this.applications.filter(
      (each) => each.productName || each.percentNutrient || each.totalKgProduct
    );
  };

  save = (resultId) => {
    fetch(`/api/soil_recommendations/${resultId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ recommendation: this.toJS() }),
    })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Save Failed:", error);
      });
    this.saveMemento();
  };
}

export default Recommendation;
