import React, { Component } from "react";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";
import Deficiencies from "./Deficiencies";
import { v4 as uuid } from "uuid";
import Recommendation from "../../models/Recommendation";
import ProductApplication from "../../models/Recommendation/ProductApplication";
import Deficiency from "../../models/deficiency";

@observer
class SoilRecommendations extends Component {
  constructor(props) {
    super(props);
    const {
      deficiencies,
      default_recommendation,
      recommendation,
      resultId,
    } = props;

    this.resultId = resultId;

    this.deficiencies = {};
    deficiencies.forEach((d, i) => {
      const def = new Deficiency(d.element, d.name, d.macro, d.units);
      this.deficiencies[def.element] = def;
    });

    this.defaultRecommendation = Recommendation.fromJS(default_recommendation);
    if (recommendation) {
      this.recommendation = Recommendation.fromJS(recommendation);
    } else {
      this.recommendation = Recommendation.fromJS(default_recommendation);
    }

    this.recommendation.saveMemento();
    this.recommendation.productCatalog.init();
  }

  @observable canEdit = false;
  @observable display = "d-block";

  @action
  changeEditMode = () => {
    this.canEdit = !this.canEdit;
  };

  @action
  displayCog = (displayMode) => {
    this.display = displayMode;
  };

  render() {
    const { permissionToEdit } = this.props;

    return (
      <div>
        <div className="row">
          {permissionToEdit && (
            <div className={"col-12 text-right d-print-none " + this.display}>
              <i
                className="fa fa-cog fa-2x"
                onClick={() => {
                  this.changeEditMode();
                  this.displayCog("d-none");
                }}
              ></i>
            </div>
          )}
        </div>

        {this.canEdit ? (
          <div className="row">
            <div className="col-12">
              <EditMode
                deficiencies={this.deficiencies}
                recommendation={this.recommendation}
                defaultRecommendation={this.defaultRecommendation}
                cancelEditMode={this.changeEditMode}
                displayCog={this.displayCog}
                resultId={this.resultId}
              />
            </div>
          </div>
        ) : (
          <div className="row table-container">
            <div className="col-md-6 col-print-6">
              <Deficiencies deficiencies={this.deficiencies} />
            </div>
            <div className="col-md-6 col-print-6 application">
              <ViewMode
                deficiencies={this.deficiencies}
                recommendation={this.recommendation}
              />
            </div>
            <div className="col-md-12 col-print-12">
              {this.recommendation.comments}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SoilRecommendations;
